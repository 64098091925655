import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import cordovaSqliteAdapter from 'pouchdb-adapter-cordova-sqlite';
import idbAdapter from 'pouchdb-adapter-idb';
import pouchAdapterMemory from 'pouchdb-adapter-memory';
import { addRxPlugin, createRxDatabase, RxDatabase } from 'rxdb';
import { RxDBEncryptionPlugin } from 'rxdb/plugins/encryption';
import { RxDBInMemoryPlugin } from 'rxdb/plugins/in-memory';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { RxDBValidatePlugin } from 'rxdb/plugins/validate';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { GimbilDbContext, Order, OrderAppointment, Retailer } from './models';
import orderAppointmentSchema from './schemas/order-appointment.schema';
import orderSchema from './schemas/order.schema';
import retailerSchema from './schemas/retailer.schema';

@Injectable()
export class DatabaseProvider {
    private database: RxDatabase<GimbilDbContext>;

    constructor(private platform: Platform) {}

    public get() {
        return this.database;
    }

    public async clearDatabase(): Promise<void> {
        if (this.database) {
            await this.database.requestIdlePromise();
            await this.database.remove();
            this.database = null;
            await this.create();
        }
    }

    public async create() {
        if (this.database) {
            return this.database;
        }
        this.configurePlugins();
        console.log('Creating database');
        const adapter = this.detectAdaptorToBeUsed();
        console.log('Adaptor being used: ', adapter);

        this.database = await createRxDatabase({
            name: 'gimbildatabase30',
            adapter,
            pouchSettings: {
                location: 'default',
            },
            password: '140whZnU9h', // <- password (optional)
            // multiInstance: true, // <- multiInstance (optional, default: true)
            // eventReduce: false, // <- eventReduce (optional, default: true)
        });

        this.database.retailers = await this.database.collection<Retailer>({
            name: 'retailer',
            schema: retailerSchema,
        });
        this.database.orders = await this.database.collection<Order>({
            name: 'order',
            schema: orderSchema,
            migrationStrategies: {
                1: (oldDoc) => {
                    oldDoc.proxyDealerId = oldDoc.dealerId;
                    return oldDoc;
                },
            },
        });
        this.database.orderAppointments = await this.database.collection<OrderAppointment>({
            name: 'orderappointment',
            schema: orderAppointmentSchema,
        });
        return this.database;
    }

    private configurePlugins(): void {
        addRxPlugin(RxDBQueryBuilderPlugin);
        addRxPlugin(RxDBValidatePlugin);
        addRxPlugin(RxDBUpdatePlugin);
        addRxPlugin(RxDBInMemoryPlugin);
        addRxPlugin(RxDBEncryptionPlugin);
        addRxPlugin(RxDBMigrationPlugin);
        addRxPlugin(pouchAdapterMemory);
    }

    private detectAdaptorToBeUsed(): 'cordova-sqlite' | 'idb' {
        if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
            addRxPlugin(idbAdapter);
            return 'idb';
        } else {
            addRxPlugin(cordovaSqliteAdapter);
            return 'cordova-sqlite';
        }
    }
}
