import { AppointmentDateBaseHandler } from './appointmentDateBase.handler';
import { AppointmentDate, AppointmentType } from '@domain/database';
import { APPOINTMENT_UNSCHEDULED, AppointmentDateType, AppointmentDateTypes } from '../../models';

export class AppointmentDateHandler extends AppointmentDateBaseHandler {
    public handle(
        appointmentType: AppointmentType,
        appointmentDates: AppointmentDate[],
        dateType: AppointmentDateTypes
    ): number | null {
        if (dateType === AppointmentDateTypes.APPOINTMENT_DATE) {
            let appointmentDate: AppointmentDate | undefined;

            if (appointmentType === AppointmentType.Measure) {
                appointmentDate = appointmentDates.find((x) => x.dateType === AppointmentDateType.ScheduledMeasureDate);
            }

            if (appointmentType === AppointmentType.Template) {
                appointmentDate = appointmentDates.find(
                    (x) => x.dateType === AppointmentDateType.ScheduledTemplateDate
                );
            }

            if (appointmentType === AppointmentType.Install) {
                appointmentDate = appointmentDates.find((x) => x.dateType === AppointmentDateType.ScheduledInstallDate);
            }

            if (appointmentType === AppointmentType.Delivery) {
                appointmentDate = appointmentDates.find(
                    (x) => x.dateType === AppointmentDateType.ScheduledDeliveryDate
                );
            }

            return appointmentDate ? appointmentDate.dateUnix : APPOINTMENT_UNSCHEDULED;
        }

        return super.handle(appointmentType, appointmentDates, dateType);
    }
}
