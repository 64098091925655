import { Injectable } from '@angular/core';
import { ApiVersions, HttpService, IApi } from '@shared/http';
import { formatISO } from 'date-fns';
import { Observable } from 'rxjs';
import { AppointmentSaveDateDto, OrderDto } from '../models';

@Injectable()
export class OrderService implements IApi {
    public endpoint = `api/${ApiVersions.V3}/orders`;

    constructor(private httpService: HttpService) {}

    public getAllOrders(dateAfter?: Date, modifiedAfter?: Date): Observable<OrderDto[]> {
        let queryParams = '?';
        if (dateAfter) {
            queryParams += `date=${encodeURIComponent(formatISO(dateAfter))}&`;
        }

        if (modifiedAfter) {
            queryParams += `modified=${encodeURIComponent(formatISO(modifiedAfter))}&`;
        }

        return this.httpService.makeGet<OrderDto[]>(`${this.endpoint}${queryParams}`);
    }

    public getOrderDetails(environment: string, retailOrderId: number): Observable<OrderDto> {
        return this.httpService.makeGet<OrderDto>(`${this.endpoint}/${environment}/${retailOrderId}`);
    }

    public saveOrderDate$(
        environment: string,
        retailerOrderId: number,
        dateDetails: AppointmentSaveDateDto
    ): Observable<Partial<OrderDto>> {
        return this.httpService.makePatch(`${this.endpoint}/${environment}/${retailerOrderId}/date`, dateDetails);
    }
}
