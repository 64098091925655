import { Injectable } from '@angular/core';
import { ApiVersions, HttpService } from '@shared/http';
import { Observable } from 'rxjs';
import { OrderNoteDto } from './order-notes.dto';

@Injectable()
export class OrderNotesApi {
    private appointmentsEndpoint = `api/${ApiVersions.V3}/orders`;

    constructor(private httpService: HttpService) {}

    public saveOrderNote(environment: string, retailerOrderId: number, orderNote: OrderNoteDto): Observable<any> {
        return this.httpService.makePost(
            `${this.appointmentsEndpoint}/${environment}/${retailerOrderId}/note`,
            orderNote
        );
    }
}
