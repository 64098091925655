import { RxJsonSchema } from 'rxdb';

const schema: RxJsonSchema = {
    title: 'order schema',
    description: 'no description',
    version: 1,
    keyCompression: false,
    type: 'object',
    properties: {
        id: {
            type: 'string',
            primary: true,
        },
        productLineId: { type: 'number' },
        roTypeId: { type: 'number' },
        deliveryDate: { type: ['string', 'null'] },
        shipToName: { type: ['string', 'null'] },
        shipToAddress1: { type: ['string', 'null'] },
        shipToAddress2: { type: ['string', 'null'] },
        shipToCity: { type: ['string', 'null'] },
        shipToState: { type: ['string', 'null'] },
        shipToZip: { type: ['string', 'null'] },
        projectNumber: { type: ['string', 'null'] },
        storeNumber: { type: ['string', 'null'] },
        salesAssocName: { type: ['string', 'null'] },
        isVanityOrder: { type: 'boolean' },
        packingItemModels: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    dealerQty: {
                        type: 'number',
                    },
                    itemDescription: {
                        type: ['string', 'null'],
                    },
                    sku: {
                        type: ['string', 'null'],
                    },
                    mfrModel: {
                        type: ['string', 'null'],
                    },
                },
            },
        },
        environment: { type: 'string' },
        retailOrderId: { type: 'number' },
        roNumber: { type: 'string' },
        status: { type: 'number' },
        dealerId: { type: 'string' },
        proxyDealerId: { type: 'string' },
        modifiedUnix: { type: 'number' },
        roDateUnix: { type: 'number' },
        roDate: { type: 'string' },
        cabinetsPurchased: { type: 'boolean' },
        isDelivery: { type: 'boolean' },
        checklistAvailable: { type: 'boolean' },
        customer: {
            type: 'object',
            properties: {
                name: {
                    type: 'string',
                },
                address1: {
                    type: 'string',
                },
                address2: {
                    type: 'string',
                },
                city: {
                    type: 'string',
                },
                state: {
                    type: 'string',
                },
                zip: {
                    type: 'string',
                },
                countryCode: {
                    type: 'string',
                },
                phone: {
                    type: 'string',
                },
                email: {
                    type: 'string',
                },
            },
        },
        products: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    roProductId: {
                        type: 'number',
                    },
                    productId: {
                        type: 'number',
                    },
                    quantity: {
                        type: 'number',
                    },
                    uom: {
                        type: 'string',
                    },
                    sku: {
                        type: 'string',
                    },
                    description: {
                        type: 'string',
                    },
                    productType: {
                        type: 'number',
                    },
                    productLine: {
                        type: 'string',
                    },
                    thickness: {
                        type: 'string',
                    },
                    price: {
                        type: 'number',
                    },
                    extendedPrice: {
                        type: 'number',
                    },
                    thumbnailUrl: {
                        type: 'string',
                    },
                    mfrmodel: {
                        type: 'string',
                    },
                },
            },
        },
        notes: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: {
                        type: 'number',
                    },
                    userName: {
                        type: 'string',
                    },
                    dateUnix: {
                        type: 'number',
                    },
                    text: {
                        type: 'string',
                    },
                    noteTypeId: {
                        type: 'number',
                    },
                },
            },
        },
        documents: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    url: {
                        type: 'string',
                    },
                    fileName: {
                        type: 'string',
                    },
                    dateUnix: {
                        type: 'number',
                    },
                    documentType: {
                        type: 'number',
                    },
                    thumbnailUrl: {
                        type: 'string',
                    },
                    documentId: {
                        type: 'number',
                    },
                },
            },
        },
        isChangeNoteAllowed: { type: 'boolean' },
        actionsRequired: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: {
                        type: 'number',
                    },
                    description: {
                        type: 'string',
                    },
                },
            },
        },
    },
    required: ['id', 'roNumber', 'dealerId'],
    indexes: ['modifiedUnix'],
    encrypted: [
        'customer.name',
        'customer.address1',
        'customer.address2',
        'customer.city',
        'customer.state',
        'customer.zip',
        'customer.countryCode',
        'customer.phone',
        'customer.email',
    ],
};
export default schema;
