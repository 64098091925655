import { Injectable } from '@angular/core';
import { ApiVersions, HttpService } from '@shared/http';
import { Observable } from 'rxjs';
import { AppointmentDocumentType, OrderDto } from '../models';

@Injectable()
export class ImageService {
    private appointmentsEndpoint = `api/${ApiVersions.V3}/orders`;

    constructor(private httpService: HttpService) {}

    public uploadPhotos$(
        environment: string,
        retailOrderId: number,
        documentType: AppointmentDocumentType,
        photosData: (string | Blob)[]
    ): Observable<Partial<OrderDto>> {
        return this.httpService.makePost(`${this.appointmentsEndpoint}/${environment}/${retailOrderId}/photos`, {
            data: photosData,
            documentType,
        });
    }

    public deletePhoto$(envinroment: string, retailOrderId: number, documentId: number): Observable<Partial<OrderDto>> {
        return this.httpService.makeDelete(
            `${this.appointmentsEndpoint}/${envinroment}/${retailOrderId}/photos/${documentId}`
        );
    }
}
