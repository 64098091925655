export enum AppointmentDocumentType {
    Drawing = 60,
    Photo = 62,
    EstimateDrawing = 66,
    PostTemplateDrawing = 67,
    AOCCOC = 70,
    CEA = 79,
    OrderDetailsConfirmation = 100,
    UnsignedCOC = 107,
    PostTemplateCheckList = 115,
    PostInstallCheckList = 116,
}

// This is the list of DocumentTypes that will be shown in the Files tab, sorted appropriately
export const FILES_TAB_VISIBLE_DOCUMENT_TYPES: Array<AppointmentDocumentType> = [
    AppointmentDocumentType.Photo,
    AppointmentDocumentType.Drawing,
    AppointmentDocumentType.EstimateDrawing,
    AppointmentDocumentType.PostTemplateDrawing,
    AppointmentDocumentType.AOCCOC,
    AppointmentDocumentType.UnsignedCOC,
    AppointmentDocumentType.PostTemplateCheckList,
    AppointmentDocumentType.PostInstallCheckList,
];
